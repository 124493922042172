import _ from 'lodash'
import CRUD from '../../generic/crud/crud'
import URL from '../../../config/url'
import { validate } from '../voucher/voucher.service'

export const getCurrentCampaignImpl = async (shouldValidate = true) => {
    const resp = await CRUD.get(
        `${URL.publicCampaigns}/:active?q=channelId=is=null&sort=region`
    )
    const currentCampaign = _.get(resp, 'data.content[0]')
    if (currentCampaign) {
        if (shouldValidate) {
            if (!currentCampaign.voucherCode) {
                return null
            }

            try {
                await validate(currentCampaign.voucherCode)
            } catch {
                return null
            }
        }

        return currentCampaign
    }

    return null
}

const throttleTime = 5000

export const getCurrentCampaign = _.throttle(
    getCurrentCampaignImpl,
    throttleTime,
    {
        leading: true,
        trailing: false,
    }
)
