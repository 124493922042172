import jsCookie from 'js-cookie'
import { LocalStorage, SessionStorage } from '@gymondo/frontend-core/utils'
import {
    LOCAL_STORAGE_KEYS,
    COOKIE_NAMES,
    SESSION_STORAGE_KEYS,
} from '@gymondo/frontend-core/enums'
import { logout as logUserOut } from '../../api/service/login/login.service'

import * as ACTIONS from './user.constants'
import url from '../../config/url'
import api from '../../api/generic/crud/crud'

export const _clearSignupData = () => {
    jsCookie.remove(COOKIE_NAMES.INVITEE_TOKEN)
    jsCookie.remove(COOKIE_NAMES.INVITEE)
    LocalStorage.remove(LOCAL_STORAGE_KEYS.SIGNUP_IS_TRIAL)
    LocalStorage.remove(LOCAL_STORAGE_KEYS.SELECTED_PRODUCT)
    LocalStorage.remove(LOCAL_STORAGE_KEYS.SIGNUP_ACTION)
    LocalStorage.remove(LOCAL_STORAGE_KEYS.SIGNUP_ZANOX)
    SessionStorage.remove(SESSION_STORAGE_KEYS.SIGNUP_VOUCHER)
}

export const login = () => ({
    type: ACTIONS.LOGIN,
})

export const logout = () => async (dispatch) => {
    await logUserOut()

    dispatch({ type: ACTIONS.LOGOUT })
}

export const fetch = () => async (dispatch) => {
    dispatch({
        type: ACTIONS.PENDING,
    })
    try {
        const { data } = await api.get(`${url.securityContext}/me`)
        dispatch({
            type: ACTIONS.FETCH_SUCCESS,
            payload: {
                ...data?.data?.user,
                roles: data?.data?.roles,
            },
        })
    } catch (err) {
        dispatch({
            type: ACTIONS.FETCH_ERROR,
            payload: err,
        })
    }
}

export const setChannel = (payload = {}) => {
    const { id, name } = payload

    SessionStorage.remove(SESSION_STORAGE_KEYS.CHANNEL)
    SessionStorage.remove(SESSION_STORAGE_KEYS.CHANNEL_ID)

    if (id) {
        SessionStorage.set(SESSION_STORAGE_KEYS.CHANNEL_ID, id)
    }
    if (id && name) {
        SessionStorage.set(SESSION_STORAGE_KEYS.CHANNEL, name)
    }

    return {
        type: ACTIONS.SET_CHANNEL,
        payload,
    }
}

export const setSelectedProduct = (product) => {
    LocalStorage.set(LOCAL_STORAGE_KEYS.SELECTED_PRODUCT, product)

    return {
        type: ACTIONS.SET_SELECTED_PRODUCT,
        payload: product,
    }
}

export const setSignupVoucher = (voucherResp) => {
    if (voucherResp) {
        SessionStorage.set(SESSION_STORAGE_KEYS.SIGNUP_VOUCHER, voucherResp)
    } else {
        SessionStorage.remove(SESSION_STORAGE_KEYS.SIGNUP_VOUCHER)
    }

    return {
        type: ACTIONS.SET_SIGNUP_VOUCHER,
        payload: voucherResp,
    }
}

export const setSignupAction = (action) => ({
    type: ACTIONS.SET_SIGNUP_ACTION,
    payload: action,
})

export const cleanSignupData = () => {
    _clearSignupData()

    return {
        type: ACTIONS.CLEAN_SIGNUP,
    }
}

export const setTryNextStep = (value) => ({
    type: ACTIONS.TRY_NEXT_STEP,
    payload: value,
})

export const setTrial = (value) => {
    if (value) {
        LocalStorage.set(LOCAL_STORAGE_KEYS.SIGNUP_IS_TRIAL, value)
    } else {
        LocalStorage.remove(LOCAL_STORAGE_KEYS.SIGNUP_IS_TRIAL)
    }

    return {
        type: ACTIONS.SET_TRIAL,
        payload: value,
    }
}
