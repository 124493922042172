import _ from 'lodash'
import CRUD from '../../generic/crud/crud'
import URL from '../../../config/url'
import { DISCOUNT_TYPES } from '../../../config/definitions'
import { getFakeDiscount } from '../../../page/generic/checkout-package/component/product-card/utils'

export const validate = async (voucher, channelId) => {
    const query = channelId ? `?channelId=${channelId}` : ''

    return CRUD.get(`${URL.publicVouchers}/${voucher}/valid${query}`)
}

export const redeem = async (voucher) =>
    CRUD.post(`${URL.secureVouchers}/${voucher}/redeem`)

export const getFreebie = async (payload, partnerId) =>
    CRUD.post(`${URL.publicVouchers}/${partnerId}/freebie`, payload)

const COOP_DISCOUNT_REGEXP = /([0-9]*)+(M)+([0-9]*)+(D|F)/
const COOP_DISCOUNT_TYPES = {
    D: DISCOUNT_TYPES.RELATIVE,
    F: DISCOUNT_TYPES.FIXED,
}

const getValueByDiscountType = (discountType, value, currency) => {
    const discountMath = Math.floor(value < 1 ? value * 100 : value)

    switch (discountType) {
        case DISCOUNT_TYPES.RELATIVE:
            return `-${discountMath}%`
        case DISCOUNT_TYPES.FIXED: {
            return `-${value}${currency}`
        }
        default:
            return null
    }
}

export const getVoucherDiscountLabel = (voucher, currency, onlyCoopCodes) => {
    if (!voucher) {
        return null
    }

    const coopCodeMatch = voucher.code?.match(COOP_DISCOUNT_REGEXP)

    if (coopCodeMatch) {
        // eslint-disable-next-line no-unused-vars
        const [, period, interval, discountAmount, discountType] = coopCodeMatch

        return getValueByDiscountType(
            COOP_DISCOUNT_TYPES[discountType],
            discountAmount,
            currency
        )
    }

    if (!onlyCoopCodes) {
        return getValueByDiscountType(
            voucher.discount?.['@type'],
            voucher.discount?.discount,
            currency
        )
    }

    return null
}

export const getProductDiscount = (voucher, product) => {
    const fakeDiscount = getFakeDiscount(product)

    if (fakeDiscount) {
        return `-${fakeDiscount * 100}%`
    }

    const currency = _.get(product, 'currency.symbol')

    return getVoucherDiscountLabel(voucher, currency, !product.discountedPrice)
}
