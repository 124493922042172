export const SOCIAL_APPS = {
    GOOGLE: 'Google',
    FACEBOOK: 'Facebook',
    APPLE: 'SignInWithApple',
    AMAZON: 'LoginWithAmazon',
}

export const ERROR_REASONS = {
    USER_ALREADY_EXISTING: 1102,
    INCORRECT_PASSWORD: 9916,
    HMAC_VERIFICATION_FAILED: 3402,
    UNKNOWN_PAYMENT_RESPONSE: 3403,
    UNAUTHORISED_PAYMENT: 3404,
    PAYMENT_ALREADY_PROCESSED: 3406,
    ACTIVE_SUBSCRIPTION: 3400,
    INVALID_VOUCHER: 3002,
    NOT_FOUND_VOUCHER: 3000,
    VOUCHER_ACTIVE_SUBSCRIPTION: 1000,
}

export const OAUTH_ERROR_CODES = {
    DUPLICATE_USER: 'DuplicateUserException',
    INVALID_PASSWORD: 'InvalidPasswordException',
    NOT_AUTHORIZED: 'NotAuthorizedException',
    REGISTERED_VIA_SOCIAL_LOGIN: 'RegisteredViaSocialLogin',
}

export const SIGNUP_ACTIONS = {
    REACTIVATION: 'reactivation',
    REPURCHASE: 'repurchase',
    PAYMENT: 'payment',
}

export const SIGNUP_STEPS = {
    ONBOARDING: 'onboarding',
    PROVIDE_PAYMENT_INFO: 'providePaymentInfo',
}

export const REFERRAL_CODE = '2867fa0e286ee74744f2a4805371f487'

export const VOUCHER_TYPES = {
    DISCOUNT: 'Discount',
    COOPERATION: 'CooperationSignup',
    TRIAL: 'TrialSignup',
    FREEBIE: 'FreeSignup',
}

export const DISCOUNT_VOUCHER_SUBTYPES = {
    DEFAULT: 'DEFAULT',
    WINBACK: 'WINBACK',
}

export const DISCOUNT_TYPES = {
    RELATIVE: 'RelativeDiscount',
    FIXED: 'FixedDiscount',
}

export const QUERY_PARAMS = {
    SIGNUP_ACTION: 'action',
    SHOW_LOGIN_LEGACY: 'loginNeeded',
    SHOW_LOGIN: 'login',
    CHANNEL: 'channel',
    CHANNEL_ID: 'channel_id',
    PROGRAM_ID: 'program_id',
    VOUCHER: 'voucher',
    PREVENTION_VOUCHER: 'preventionVoucher',
    REFERRAL_ID: 'refId',
    RESET_PASSWORD_CODE: 'code',
    FORWARD_URL: 'fw',
    SELECTED_PAYMENT_METHOD: 'paymentMethod',
    RESULT_CODE: 'resultCode',
    REFUSAL_REASON: 'refusalReason',
    SOVENDUS_TOKEN: 'sovReqToken',
    ZANOX_TOKEN_ZANPID: 'zanpid',
    ZANOX_TOKEN_AWC: 'awc',
    USERNAME: 'u',
    PSP_REFERENCE: 'pspReference',
    SHOPPER_REFERENCE: 'shopperReference',
    SESSION_ID: 'sessionId',
    REDIRECT_RESULT: 'redirectResult',
}

export const AUTHORIZATION_TYPES = {
    SETUP: 'SETUP',
    RE_ENTER: 'RE_ENTER',
    RE_PURCHASE: 'RE_PURCHASE',
}

export const AFFILIATE_PARTNERS = {
    ZANOX: 'ZANOX',
    SOVENDUS: 'SOVENDUS',
}
