import AdyenCheckout from '@adyen/adyen-web'
import { configuration } from './config/provider.config'
import CRUD from '../../../../../api/generic/crud/crud'
import URL from '../../../../../config/url'
import { sendTrackingData } from './tracking.service'
import { _clearSignupData } from '../../../../../service/user'
import {
    redirectToTimeline,
    redirectToOnboarding,
} from '../../../../../util/location.util'
import { SIGNUP_ACTIONS } from '../../../../../config/definitions'
import { waitFor } from '../../../../../util/utils.util'

export const createSession = async (
    product,
    channelId,
    signupAction,
    paymentMethod
) => {
    let transactionType = 'SETUP'
    let finalProduct = product
    if (signupAction === SIGNUP_ACTIONS.REPURCHASE) {
        transactionType = 'RE_PURCHASE'
    }
    if (signupAction === SIGNUP_ACTIONS.PAYMENT) {
        transactionType = 'RE_ENTER'
        finalProduct = null
    }
    if (signupAction === SIGNUP_ACTIONS.REACTIVATION) {
        transactionType = 'REACTIVATION'
        finalProduct = null
    }
    const { data } = await CRUD.post(`${URL.frontendPayment}/sessions`, {
        product: finalProduct,
        channelId,
        transactionType,
        paymentMethod,
    })

    return data
}

const getPendingOperations = async () => {
    const { data } = await CRUD.get(URL.secureSettingsPending, {
        withCredentials: true,
    })

    return data
}

export const pollSubscriptionCreated = () => {
    return waitFor(async () => {
        let pendingOperations

        try {
            pendingOperations = await getPendingOperations()
        } catch (e) {
            return false
        }

        const pollNewUser =
            pendingOperations.onboarding &&
            pendingOperations.subscriptionCreation

        const pollOldUser =
            pendingOperations.payment && pendingOperations.subscriptionCreation

        return !pollNewUser && !pollOldUser
    })
}

export const handleSuccess = async (user) => {
    await pollSubscriptionCreated()

    await sendTrackingData(user)

    _clearSignupData()

    if (
        user.signupAction === SIGNUP_ACTIONS.PAYMENT ||
        user.signupAction === SIGNUP_ACTIONS.REACTIVATION ||
        user.signupAction === SIGNUP_ACTIONS.REPURCHASE
    ) {
        redirectToTimeline()
    } else {
        redirectToOnboarding()
    }
}

export const createCheckoutInstance = (additinalConfig, session) =>
    AdyenCheckout({
        ...configuration,
        ...additinalConfig,
        session,
    })
