import jsCookie from 'js-cookie'
import _ from 'lodash'
import { LocalStorage, SessionStorage } from '@gymondo/frontend-core/utils'
import {
    COOKIE_NAMES,
    SESSION_STORAGE_KEYS,
    LOCAL_STORAGE_KEYS,
} from '@gymondo/frontend-core/enums'
import moment from 'moment'
import * as ACTIONS from './user.constants'
import status from '../../api/generic/redux/status.enum'
import { SIGNUP_ACTIONS, VOUCHER_TYPES } from '../../config/definitions'

const loggedInCookie = jsCookie.get(COOKIE_NAMES.OAUTH2_REFRESH_TOKEN)
const existingUserCookie =
    typeof window !== 'undefined' && window.document
        ? jsCookie.get(COOKIE_NAMES.EXISTING_USER)
        : true
const channelName = SessionStorage.get(SESSION_STORAGE_KEYS.CHANNEL)
const channelId = SessionStorage.get(SESSION_STORAGE_KEYS.CHANNEL_ID)
const selectedProduct = LocalStorage.get(LOCAL_STORAGE_KEYS.SELECTED_PRODUCT)
const signupAction = LocalStorage.get(LOCAL_STORAGE_KEYS.SIGNUP_ACTION)
const isTrial = LocalStorage.get(LOCAL_STORAGE_KEYS.SIGNUP_IS_TRIAL)
const signupVoucher = SessionStorage.get(SESSION_STORAGE_KEYS.SIGNUP_VOUCHER)

const getEndTrialTime = (product, voucher) => {
    const interval =
        _.get(voucher, 'selectedProduct.trialProduct.interval') ||
        _.get(product, 'trialProduct.interval')

    if (voucher && voucher['@type'] === VOUCHER_TYPES.TRIAL) {
        return {
            trialInterval:
                voucher.trialDays !== undefined &&
                !Number.isNaN(voucher.trialDays)
                    ? `${voucher.trialDays} days`
                    : interval,
            endTrialTime: moment(new Date())
                .add(voucher.trialDays, 'days')
                .valueOf(),
        }
    }

    if (!interval) {
        return {
            trialInterval: null,
            endTrialTime: null,
        }
    }

    const time = interval.split(' ')

    return {
        trialInterval: interval,
        endTrialTime: moment().add(time[0], time[1]).valueOf(),
    }
}

const getSignupAction = (currentSignupAction, roles) => {
    if (
        roles &&
        roles.includes('ROLE_USER_EXPIRED') &&
        !roles.includes('ROLE_USER_INACTIVE')
    ) {
        return SIGNUP_ACTIONS.REPURCHASE
    }
    if (
        roles &&
        roles.includes('ROLE_USER_DISABLED') &&
        roles.includes('ROLE_USER_REACTIVATION_ALLOWED')
    ) {
        return SIGNUP_ACTIONS.REACTIVATION
    }

    return currentSignupAction
}

const initialState = {
    isLoggedIn: !!loggedInCookie,
    isExistingUser: !!existingUserCookie,
    isTrial: !!isTrial,
    selectedProduct,
    channel:
        !channelName && !channelId
            ? null
            : { id: channelId, name: channelName },
    signupAction,
    signupVoucher,
    userName: null,
    error: null,
    roles: null,
    shouldTryNextStep: false,
    status: status.PENDING,
    ...getEndTrialTime(selectedProduct, signupVoucher),
}

const reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ACTIONS.LOGIN:
            return {
                ...state,
                isLoggedIn: true,
                status: status.OK,
            }
        case ACTIONS.LOGOUT: {
            return {
                ...state,
                userName: null,
                isLoggedIn: false,
                isTrial: false,
                signupAction: null,
            }
        }
        case ACTIONS.PENDING: {
            return {
                ...state,
                status: status.PENDING,
            }
        }
        case ACTIONS.FETCH_SUCCESS:
            return {
                ...state,
                userName: payload.username,
                id: payload.id,
                status: status.OK,
                roles: payload.roles,
                signupAction: getSignupAction(
                    state.signupAction,
                    payload.roles
                ),
            }
        case ACTIONS.FETCH_ERROR:
            return {
                ...state,
                userName: null,
                error: payload,
                status: status.ERROR,
            }
        case ACTIONS.SET_CHANNEL:
            return {
                ...state,
                channel: payload,
            }
        case ACTIONS.SET_SELECTED_PRODUCT:
            return {
                ...state,
                selectedProduct: payload,
                ...getEndTrialTime(payload, state.signupVoucher),
            }
        case ACTIONS.SET_SIGNUP_ACTION:
            return {
                ...state,
                signupAction: payload,
            }
        case ACTIONS.SET_SIGNUP_VOUCHER:
            return {
                ...state,
                signupVoucher: payload,
                ...getEndTrialTime(state.selectedProduct, payload),
            }
        case ACTIONS.TRY_NEXT_STEP:
            return {
                ...state,
                shouldTryNextStep: payload,
            }
        case ACTIONS.SET_TRIAL: {
            return {
                ...state,
                isTrial: payload,
            }
        }
        default:
            return state
    }
}

export default reducer
